<template>
  <div style="height: inherit">

    <b-row>
      <b-col>
        <b-card :title="group.name !== '' ? group.name : $t('New Group')">
          <b-row
            v-if="group.players"
            class="mb-1"
          >
            <b-col md="4">
              <v-select
                id="country"
                v-model="newPlayer"
                :options="players"
                :selectable="option => checkPlayerSelectable(option.id)"
                label="text"
                :placeholder="$t('Add Players')"
                @input="addPlayer"
              />
            </b-col>
            <b-col md="4">
              <b-form-input
                v-model="group.name"
                :placeholder="$t('Group Name (Mandatory)')"
                @keyup="changed = true"
              />
            </b-col>
            <b-col
              md="4"
              class="text-right"
            >
              <b-form-input
                v-model="teamsCoach"
                :placeholder="$t('Coach Name (Filter)')"
              />
            </b-col>
          </b-row>

          <spider-table
            v-if="rows.length > 0"
            :title="$t('Players')"
            :rows="rows"
            :columns="columns"
            :page-length="20"
            @deletePlayerFromGroup="removePlayer"
          />

          <p
            v-else
          />

          <b-button
            v-if="fullChangedCheck"
            variant="success"
            @click="saveChanges"
          >
            {{ $t('Save') }}
          </b-button>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpiderTable from '../../components/SpiderTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormInput,
    SpiderTable,
  },
  props: {
    groupId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      teamsCoach: '',
      changed: false,
      group: {},
      newPlayer: '',
      players: [],
      searchTerm: '',
    }
  },
  computed: {
    fullChangedCheck() {
      return (this.changed || (this.teamsCoach !== this.group.coach && this.teamsCoach !== '')) && this.group.name !== '' && this.group.players.length > 0
    },
    rows() {
      if (this.group.players === undefined) {
        return []
      }
      return this.group.players.map(item => {
        let result = this.players.find(obj => obj.id === item)
        if (result === undefined) {
          result = {
            avatar: '#',
            full_name: 'No Data Available',
            id: item,
            nbrounds: 'NA',
            sghandicap: 'NA',
          }
        }
        return result
      })
    },
    columns() {
      return [
        {
          label: this.$t('Name'),
          field: 'full_name',
          width: '50%',
        },
        {
          label: this.$t('NBR Rounds'),
          field: 'nbrounds',
          width: '20%',
        },
        {
          label: this.$t('Spider HCP'),
          field: 'sghandicap',
          formatFn: this.formatHandicap,
          width: '20%',
        },
        {
          label: '',
          field: 'group_player_actions',
          width: '10%',
          tdClass: 'text-center',
        },
      ]
    },
  },
  created() {
    /* eslint-disable no-param-reassign */
    this.$store.dispatch('spider/updateGroups').then(() => {
      this.group = this.$store.state.spider.groups.find(obj => obj.id === this.groupId)
      this.teamsCoach = this.group.coach
    })
    this.$store.dispatch('spider/updatePlayers').then(() => {
      this.players = this.$store.state.spider.players.map(item => {
        item.value = item.id
        item.text = item.full_name
        return item
      })
    })
    /* eslint-disable no-param-reassign */
  },
  methods: {
    addPlayer() {
      const tempGroupId = this.groupId
      const tempPlayerId = this.newPlayer.id
      if (this.group.players.includes(this.newPlayer.id)) {
        this.newPlayer = ''
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Player already in group',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'This player is already in your group',
          },
        })
      } else {
        store.commit('spider/addPlayerToGroup', { playerId: tempPlayerId, groupId: tempGroupId })
        this.newPlayer = ''
        this.changed = true
      }
    },
    removePlayer(playerId) {
      store.commit('spider/removePlayerFromGroup', { playerId, groupId: this.groupId })
      this.changed = true
    },
    formatHandicap(score) {
      return score === 1000 ? '-' : (Math.round(score * 10) / 10).toFixed(1)
    },
    saveChanges() {
      store.commit('spider/updateGroupCoach', { groupId: this.group.id, coachName: this.teamsCoach })
      store.dispatch('spider/updateGroupData', this.groupId).then(() => {
        this.changed = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Group updated',
            icon: 'UserCheckIcon',
            variant: 'success',
            text: 'Group succesfully updated',
          },
        })
        this.$router.push({ name: 'groups' })
        // if (this.groupId === -1) {
        //   this.$router.push({ name: 'edit-group', params: { groupId: response.data.id } })
        // }
      })
    },
    checkPlayerSelectable(id) {
      if (this.group.length === 0) {
        return true
      }
      return !this.group.players.includes(id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
